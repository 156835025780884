/*
Created By: Robert Tew @ http://valleywebdesigns.uk
Date: January 13. 2015
Copyright 2015 Robert Tew
*/


jQuery(document).ready(function( $ ) {
	
 $('form #cf-response').hide();
	
	$("#contactForm").submit(function(e) {
		
		//alert("Hello! I am an alert box!!");
		
		// prevent forms default action until
		// error check has been performed
		e.preventDefault();
		e.stopImmediatePropagation()
				
		// grab form field values
		var valid = '';
		var required = ' is required.';
		var icon = '<i class="fa fa-exclamation-triangle"></i> ';
		
		var cf_the_name = $('form #cf_the_name').val();
		var cf_the_lname = $('form #cf_the_lname').val();
		var cf_phone = $('form #cf_phone').val();
		
		var cf_email = $('form #cf_email').val();
		var cf_message = $('form #cf_message').val();
	
		var honeypot = $('form #honeypot').val();
		var humancheck = $('form #humancheck').val();
		
		// perform error checking
		if (cf_the_name = '' || cf_the_name.length <= 2) {
			valid = '<p>' + icon + 'Your name' + required +'</p>';	
			$('#cf_the_name').addClass('uk-form-danger')
		} else {$('#cf_the_name').removeClass('uk-form-danger')}
		
		
		if (cf_the_lname = '' || cf_the_lname.length <= 2) {
			valid = '<p>' + icon + 'Your last name' + required +'</p>';	
			$('#cf_the_lname').addClass('uk-form-danger')
		} else {$('#cf_the_lname').removeClass('uk-form-danger')}
		
		if (cf_phone = '' || cf_phone.length <= 5) {
			valid = '<p>' + icon + 'Your phone number' + required +'</p>';	
			$('#cf_phone').addClass('uk-form-danger')
		} else {$('#cf_phone').removeClass('uk-form-danger')}
		
		
		if (!cf_email.match(/^([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4}$)/i)) {
			valid += '<p>' + icon + 'Your email' + required +'</p>';	
			$('#cf_email').addClass('uk-form-danger')
		} else {$('#cf_email').removeClass('uk-form-danger')}
														  
		
		if (cf_message = '' || cf_message.length <= 10) {
			valid += '<p>' + icon + 'A message' + required + '</p>';
			$('#cf_message').addClass('uk-form-danger')
		} else {$('#cf_message').removeClass('uk-form-danger')}	
		
		
		if (honeypot != 'http://') {
			valid += '<p>' + icon + 'Spambots are not allowed.</p>';	
		}
		
		if (humancheck != '') {
			valid += '<p>' + icon + 'A human user' + required + '</p>';	
		}
		
		// let the user know if there are erros with the form
		if (valid != '') {
			
			$('form #cf-response').removeClass().addClass('uk-width-small-1-1 uk-alert uk-alert-danger')
				.html('<strong>Please correct the errors below.</strong>' +valid).fadeIn('fast');			
		}
		// let the user know something is happening behind the scenes
		// serialize the form data and send to our ajax function
		else {
			
			$('form #cf-response').removeClass().addClass('processing').html('Processing...').fadeIn('fast');	
			
			
			
		var cformData = new FormData($(this)[0]);
		cformData.append('action', 'send_ajax_contact_form');												
		submitContactForm(cformData);			

		}			
			
});		
			
});


// make our ajax request to the server
function submitContactForm(cformData) {
	
	$.ajax({	
		type: 'POST',
		
		data: cformData,
type: 'POST',
url : MyAjaxContact.ajax_url,	
async: false,// for formdata object
cache: false,// for formdata object
contentType: false,// for formdata object
processData: false,// for formdata object
		
		success: function(data) { 			
				//$('form #response').removeClass().addClass('processing').html('sucess...').fadeIn('fast');	
			$('form #cf-response').removeClass().addClass((data.error === true) ? 'uk-width-small-1-1 uk-alert uk-alert-danger' : 'success uk-width-small-1-1')
						.html(data.msg).fadeIn('fast');
						
					
						
			if ($('form #cf-response').hasClass('success')) {
						
				//setTimeout("$('form #response').fadeOut('fast')", 5000);
				
				$('form')[0].reset(); // reset the form on successful completion
			}
		
		
		
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {
					
			$('form #cf-response').removeClass().addClass('error uk-width-small-1-1')
						.html('<p>There was an<strong> ' + errorThrown +
							  '</strong> error due to a<strong> ' + textStatus +
							  '</strong> condition.</p>').fadeIn('fast');			
						

		},				
	/*	complete: function(XMLHttpRequest, status) { 			
			
			
		}*/
	});	
	
};







//===================================================================		
//  Callback Request Form
//===================================================================
jQuery(document).ready(function( $ ) {
	
	$('form#quoteForm #qf_response').hide();
	
	$('form#quoteForm').submit(function(e) {
		
		// prevent forms default action until
		// error check has been performed
		e.preventDefault();
		e.stopImmediatePropagation()
				
		// grab form field values
		var valid = '';
		var required = ' is required.';
		var icon = '<i class="fa fa-exclamation-triangle"></i> ';
		
		var qf_the_name = $('form #qf_the_name').val();
		var qf_email = $('form #qf_email').val();
		var qf_phone = $('form #qf_phone').val();
		var qf_address = $('form #qf_address').val();
		var qf_message = $('form #qf_message').val();
		var qf_contactMethod = $('form #qf_contactMethod').val();
		

		var qf_honeypot = $('form #qf_honeypot').val();
		var qf_humancheck = $('form #qf_humancheck').val();
		
		// perform error checking
		if (qf_the_name = '' || qf_the_name.length <= 2) {
			valid = '<p>' + icon + 'Your name' + required +'</p>';	
		}
		
		if (!qf_email.match(/^([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4}$)/i)) {
			valid += '<p>' + icon + 'Your email' + required +'</p>';												  
		}
	/*	if (qf_phone = '' || qf_phone.length <= 5) {
			valid += '<p>' + icon + 'Your phone number' + required + '</p>';	
		}
		if (qf_message = '' || qf_message.length <= 5) {
			valid += '<p>' + icon + 'A message' + required + '</p>';	
		}
		if (qf_address = '' || qf_address.length <= 5) {
			valid += '<p>' + icon + 'Your address' + required + '</p>';	
		}*/
		
			if( !$('#qf_contactMethod').val() ) { 
			valid += '<p>' + icon + 'A preferred contact method ' + required +'</p>';	
		}
		
		
		if( $("form#quoteForm #timebottom").is(":visible") &&  (!$(qf_specifictime).val())  ) {
				valid += '<p>' + icon + 'A convenient time ' + required +'</p>';
		}
		
	/*	if (honeypot != 'http://') {
			valid += '<p>' + icon + 'Spambots are not allowed.</p>';	
		}
		
		if (humancheck != '') {
			valid += '<p>' + icon + 'A human user' + required + '</p>';	
		}*/
		
		// let the user know if there are erros with the form
		if (valid != '') {
			
			$('form#quoteForm #qf_response').removeClass().addClass('error')
				.html('<strong>Please correct the errors below.</strong>' +valid).fadeIn('fast');			
		}
		// let the user know something is happening behind the scenes
		// serialize the form data and send to our ajax function
		else {
			
			$('form#quoteForm #qf_response').removeClass().addClass('processing').html('Processing...').fadeIn('fast');	
			
			 var qformData = new FormData($(this)[0]);
  
  // append the wordpress action function into the formData object
  qformData.append('action', 'send_quote_form');									
			
		//	var formData = $('form').serialize();
			submitQuoteForm(qformData);			
		}			
			
	
});		
		
		
	
	
});




// make our ajax request to the server
function submitQuoteForm(qformData) {
	
	$.ajax({	

data: qformData,
type: 'POST',
url : MyQuoteForm.ajax_url,	
async: true,// for formdata object
cache: false,// for formdata object
contentType: false,// for formdata object
processData: false,// for formdata object

success: function(data) { 			

$('form#quoteForm #qf_response').removeClass().addClass((data.error === true) ? 'error uk-width-small-1-1' : 'success uk-width-small-1-1')

.html(data.msg).fadeIn('fast');	

if ($('form#quoteForm #qf_response').hasClass('success')) {

//setTimeout("$('form #qfresponse').fadeOut('fast')", 5000);

document.getElementById("quoteForm").reset();


}
						
			
		
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {
						
			$('form#quoteForm #qf_response').removeClass().addClass('error uk-width-small-1-1 ')
						.html('<p>There was an<strong> ' + errorThrown +
							  '</strong> error due to a<strong> ' + textStatus +
							  '</strong> condition.</p>').fadeIn('fast');			
		},				
		complete: function(XMLHttpRequest, status) { 			
			
			// $('form')[0].reset();
		}
	});	
};



//===================================================================		
// END of Comment
//===================================================================	









//===================================================================		
//  Product Enquiry Form
//===================================================================
jQuery(document).ready(function( $ ) {
	
	$('form#EnqForm #enq_response').hide();
	
	$('#EnqForm').submit(function(e) {
		
		// prevent forms default action until
		// error check has been performed
		e.preventDefault();
		e.stopImmediatePropagation()
				
		// grab form field values
		var valid = '';
		var required = ' is required.';
		var icon = '<i class="fa fa-exclamation-triangle"></i> ';
		
		var enq_the_name = $('form #enq_the_name').val();
		var enq_email = $('form #enq_email').val();
		var enq_phone = $('form #enq_phone').val();
		var enq_address = $('form #enq_address').val();
		var enq_message = $('form #enq_message').val();
		var enq_contactMethod = $('form #enq_contactMethod').val();
		

		var honeypot = $('form#EnqForm #honeypot').val();
		var humancheck = $('form#EnqForm #humancheck').val();
		
		// perform error checking
		if (enq_the_name = '' || enq_the_name.length <= 2) {
			valid = '<p>' + icon + 'Your name' + required +'</p>';	
		}
		
		if (!enq_email.match(/^([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4}$)/i)) {
			valid += '<p>' + icon + 'Your email' + required +'</p>';												  
		}
		if (enq_message = '' || enq_message.length <= 5) {
			valid += '<p>' + icon + 'A message' + required + '</p>';	
		}
		
	/*	if (enq_phone = '' || enq_phone.length <= 5) {
			valid += '<p>' + icon + 'Your phone number' + required + '</p>';	
		}
		
		if (enq_address = '' || enq_address.length <= 5) {
			valid += '<p>' + icon + 'Your address' + required + '</p>';	
		}*/
		
			if( !$('#enq_contactMethod').val() ) { 
			valid += '<p>' + icon + 'A preferred contact method ' + required +'</p>';	
		}
		
		
		if( $("form#EnqForm #enq_timebottom").is(":visible") &&  (!$(enq_specifictime).val())  ) {
				valid += '<p>' + icon + 'A convenient time ' + required +'</p>';
		}
		
	/*	if (honeypot != 'http://') {
			valid += '<p>' + icon + 'Spambots are not allowed.</p>';	
		}
		
		if (humancheck != '') {
			valid += '<p>' + icon + 'A human user' + required + '</p>';	
		}*/
		
		// let the user know if there are erros with the form
		if (valid != '') {
			
			$('form#EnqForm #enq_response').removeClass().addClass('error')
				.html('<strong>Please correct the errors below.</strong>' +valid).fadeIn('fast');			
		}
		// let the user know something is happening behind the scenes
		// serialize the form data and send to our ajax function
		else {
			
				$('form#EnqForm #enq_response').removeClass().addClass('processing').html('Processing...').fadeIn('fast');	
	
			 var enqformData = new FormData($(this)[0]);
  
  // append the wordpress action function into the formData object
  enqformData.append('action', 'send_ProdEnqForm');									
			
		//	var formData = $('form').serialize();
			submitEnqForm(enqformData);			
		}			
			
	
});		
		
		
	
	
});




// make our ajax request to the server
function submitEnqForm(enqformData) {
	
	$.ajax({	

data: enqformData,
type: 'POST',
url : ProdEnqForm.ajax_url,	
async: true,// for formdata object
cache: false,// for formdata object
contentType: false,// for formdata object
processData: false,// for formdata object

success: function(data) { 	
						
$('form#EnqForm #enq_response').removeClass().addClass((data.error === true) ? 'error uk-width-small-1-1' : 'success uk-width-small-1-1')

.html(data.msg).fadeIn('fast');


		/*	if ($('form#EnqForm #enq_response').hasClass('success')) {
				
				//setTimeout("$('form #enq_response').fadeOut('fast')", 5000);
				
				
			}*/
		
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {
						
			$('form#EnqForm #enq_response').removeClass().addClass('error uk-width-small-1-1 ')
						.html('<p>There was an<strong> ' + errorThrown +
							  '</strong> error due to a<strong> ' + textStatus +
							  '</strong> condition.</p>').fadeIn('fast');			
		},				
		complete: function(XMLHttpRequest, status) { 			
			
			// $('form')[0].reset();
		}
	});	
};



//===================================================================		
// END of Comment
//===================================================================	


































//===================================================================		
//  Callback Request Form
//===================================================================
jQuery(document).ready(function( $ ) {
	
	$('form#kitchenQuote #kq_response').hide();
	
	$('form#kitchenQuote').submit(function(e) {
		
		// prevent forms default action until
		// error check has been performed
		e.preventDefault();
		e.stopImmediatePropagation()
				
		// grab form field values
		var valid = '';
		var required = ' is required.';
		var icon = '<i class="fa fa-exclamation-triangle"></i> ';
		
		var kq_the_name = $('form #kq_the_name').val();
		var kq_email = $('form #kq_email').val();
		var kq_phone = $('form #kq_phone').val();
		var kq_address = $('form #kq_address').val();
		var kq_message = $('form #kq_message').val();
		var kq_contactMethod = $('form #kq_contactMethod').val();
		

		var kq_honeypot = $('form #kq_honeypot').val();
		var kq_humancheck = $('form #kq_humancheck').val();
		
		// perform error checking
		if (kq_the_name = '' || kq_the_name.length <= 2) {
			valid = '<p>' + icon + 'Your name' + required +'</p>';	
		}
		
		if (!kq_email.match(/^([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4}$)/i)) {
			valid += '<p>' + icon + 'Your email' + required +'</p>';												  
		}
	/*	if (kq_phone = '' || kq_phone.length <= 5) {
			valid += '<p>' + icon + 'Your phone number' + required + '</p>';	
		}
		if (kq_message = '' || kq_message.length <= 5) {
			valid += '<p>' + icon + 'A message' + required + '</p>';	
		}
		if (kq_address = '' || kq_address.length <= 5) {
			valid += '<p>' + icon + 'Your address' + required + '</p>';	
		}*/
		
			if( !$('#kq_contactMethod').val() ) { 
			valid += '<p>' + icon + 'A preferred contact method ' + required +'</p>';	
		}
		
		
		if( $("form#kitchenQuote #timebottom").is(":visible") &&  (!$(kq_specifictime).val())  ) {
				valid += '<p>' + icon + 'A convenient time ' + required +'</p>';
		}
		
	/*	if (honeypot != 'http://') {
			valid += '<p>' + icon + 'Spambots are not allowed.</p>';	
		}
		
		if (humancheck != '') {
			valid += '<p>' + icon + 'A human user' + required + '</p>';	
		}*/
		
		// let the user know if there are erros with the form
		if (valid != '') {
			
			$('form#kitchenQuote #kq_response').removeClass().addClass('error')
				.html('<strong>Please correct the errors below.</strong>' +valid).fadeIn('fast');			
		}
		// let the user know something is happening behind the scenes
		// serialize the form data and send to our ajax function
		else {
			
			$('form#kitchenQuote #kq_response').removeClass().addClass('processing').html('Processing...').fadeIn('fast');	
			
			 var kqformData = new FormData($(this)[0]);
  
  // IMPORTANT !!!
  // append the wordpress action function into the formData object
  kqformData.append('action', 'send_KitchenQuote');									
			
		//	var formData = $('form').serialize();
			submitkitchenQuote(kqformData);			
		}			
			
	
});		
		
		
	
	
});




// make our ajax request to the server
function submitkitchenQuote(kqformData) {
	
	$.ajax({	

data: kqformData,
type: 'POST',
url : KitchenQuote.ajax_url,	
async: true,// for formdata object
cache: false,// for formdata object
contentType: false,// for formdata object
processData: false,// for formdata object

success: function(data) { 			

$('form#kitchenQuote #kq_response').removeClass().addClass((data.error === true) ? 'error uk-width-small-1-1' : 'success uk-width-small-1-1')

.html(data.msg).fadeIn('fast');	

if ($('form#kitchenQuote #kq_response').hasClass('success')) {

//setTimeout("$('form #qfresponse').fadeOut('fast')", 5000);

document.getElementById("kitchenQuote").reset();


}
						
			
		
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {
						
			$('form#kitchenQuote #kq_response').removeClass().addClass('error uk-width-small-1-1 ')
						.html('<p>There was an<strong> ' + errorThrown +
							  '</strong> error due to a<strong> ' + textStatus +
							  '</strong> condition.</p>').fadeIn('fast');			
		},				
		complete: function(XMLHttpRequest, status) { 			
			
			// $('form')[0].reset();
		}
	});	
};



//===================================================================		
// END of Comment
//===================================================================	

